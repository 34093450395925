var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group string",
      class: _vm.extraReqWidthClass(_vm.field, "has-error")
    },
    [
      _c(
        "label",
        {
          staticClass: "control-label string",
          class: _vm.field.required ? "required" : "optional",
          attrs: {
            for: _vm.controlId(_vm.field.name),
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.field.description
          }
        },
        [
          _vm.field.required
            ? _c("abbr", { attrs: { title: "obrigatorio" } }, [_vm._v("*")])
            : _vm._e(),
          _vm._v("\n      " + _vm._s(_vm.field.label) + "\n\n"),
          _vm._v(" "),
          _vm.field.description
            ? _c(
                "span",
                {
                  staticClass: ".bg-muted",
                  staticStyle: {
                    display: "inline-block",
                    visibility: "visible",
                    color: "#fff",
                    background: "#000",
                    width: "16px",
                    height: "16px",
                    "border-radius": "8px",
                    "text-align": "center",
                    "line-height": "16px",
                    margin: "0 5px",
                    "font-size": "12px",
                    cursor: "default"
                  },
                  attrs: { tooltip: _vm.field.description }
                },
                [_vm._v("\n            ?\n      ")]
              )
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.value
        ? _c("input", {
            staticClass: "form-control string",
            class: _vm.field.required ? "required" : "optional",
            attrs: {
              type: "text",
              maxlength: _vm.field.maxlength,
              placeholder: _vm.field.placeholder,
              name: _vm.controlName(_vm.field.name),
              id: _vm.controlId(_vm.field.name)
            },
            domProps: { value: _vm.value }
          })
        : _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.compFieldValue,
                expression: "compFieldValue"
              }
            ],
            staticClass: "form-control string",
            class: _vm.field.required ? "required" : "optional",
            attrs: {
              type: "text",
              maxlength: _vm.field.maxlength,
              placeholder: _vm.field.placeholder,
              name: _vm.controlName(_vm.field.name),
              id: _vm.controlId(_vm.field.name)
            },
            domProps: { value: _vm.compFieldValue },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.compFieldValue = $event.target.value
              }
            }
          }),
      _vm._v(" "),
      _c("span", { staticClass: "help-block" }, [_vm._v(_vm._s(_vm.error))])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }