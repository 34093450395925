var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ibox" },
    [
      _vm.model.title != "" ||
      _vm.model.actions.length > 0 ||
      _vm.model.buttons.length > 0
        ? _c("cr-title", {
            attrs: {
              title: _vm.model.title,
              subtitle: _vm.model.subtitle,
              raw_subtitle: _vm.model.raw_subtitle,
              doc_link: _vm.model.doc_link,
              actions: _vm.model.actions,
              buttons: _vm.model.buttons,
              button_downs: _vm.model.button_downs
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.model.tabledef.type == "topicos_page"
        ? _c(
            "div",
            { staticClass: "row topicos_page" },
            [
              _c("user-topicos", {
                attrs: { options: _vm.model.tabledef.options }
              })
            ],
            1
          )
        : _c("div", { staticClass: "ibox-content" }, [
            _vm.model.tabledef.type == "data_table"
              ? _c(
                  "div",
                  { staticClass: "project-list" },
                  [
                    _c("cr-data-table", {
                      ref: "data_table_" + _vm.model.tabledef.id,
                      attrs: { options: _vm.model.tabledef }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.model.tabledef.type == "abas"
              ? _c(
                  "div",
                  { staticClass: "abas" },
                  [
                    _c("cr-abas", {
                      attrs: {
                        options: _vm.options_for_abas(_vm.model.tabledef.abas)
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.model.tabledef.type == "scoped_show"
              ? _c(
                  "div",
                  { staticClass: "scoped_show" },
                  [
                    _c("cr-scoped-show", {
                      attrs: { opts: _vm.model.tabledef }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.model.tabledef.type == "raw"
              ? _c("div", { staticClass: "raw_content" }, [
                  _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.model.tabledef.raw_content)
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.model.tabledef.type == "email_index"
              ? _c("div", { staticClass: "emailindex" }, [_c("email-index")], 1)
              : _vm._e(),
            _vm._v(" "),
            _vm.model.tabledef.type == "tree_foam"
              ? _c(
                  "div",
                  { staticClass: "tree_foam" },
                  [
                    _c("tree-foam", {
                      attrs: { options: _vm.model.tabledef.options }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.model.tabledef.type == "vertical_tree_etapans"
              ? _c(
                  "div",
                  { staticClass: "vertical_tree_etapans" },
                  [
                    _c("cr-vertical-tree-etapans", {
                      attrs: { options: _vm.model.tabledef.tree }
                    })
                  ],
                  1
                )
              : _vm._e()
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }