<template>
  <div class="ibox-title ch-alt"
       :class="klass">
    <div class="media-body m-t-5">
      <div :class="titleLargeClass()">
        <h2 id="show_page_title"> {{ title }} </h2>
        <h5> {{ subtitle }} </h5>
        <h5 v-if="raw_subtitle"
            :class="raw_subtitle.class"
            v-html="raw_subtitle.content"> </h5>

        <h5 v-if="doc_link"
            :class="doc_link.class"
            v-html="doc_link.content"> </h5>

      </div>
      <div :class="buttonsLargeClass()">
        <div class="ibox-tools">
          <a v-for="button in buttons"
              :href="button.href"
              :target="button.target"
              :class="button.class"
              :data-remote="button.data_remote">
            <div>
              <i v-if="button.i_class"
                 class="p-r-5"
                 :class="button.i_class"></i>
              {{ button.caption }}
            </div>
            <small :style="button.small_style"
            >  {{ button.small }}
            </small>
          </a>

          <div v-for="bd in button_downs"
               class="btn-group">
            <button :class="bd.class"
                    data-toggle="dropdown">
              <i :class="bd.i_class"></i>
              {{ bd.caption }}
              <span class="caret"></span>
            </button>
            <ul class="dropdown-menu">
              <li v-for="btn in bd.buttons">
                <a :data-remote="btn.data_remote"
                   :href="btn.href">
                  {{ btn.caption }}
                </a>
              </li>
            </ul>
          </div>

        </div>
      </div>
      <div id="header_actions" class="click-actions">
        <div v-if="actions && (typeof(actions) == 'object')"
             class="ibox-tools">
          <a v-for="action in actions"
             :data-remote="action.others['data-remote']"
             :data-toggle="action.others['data-toggle']"
             :data-placement="action.others['data-placement']"
             :title="action.others.title"
             :href="action.href">
            <i :class="action.i_class"
               :id="action.others.id_li"
            ></i>
          </a>
        </div>
        <div v-if="actions && (typeof(actions) == 'string')"
             class="ibox-tools"
             v-html="actions">
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import CrAction     from './cr_action.vue' ;
  import CrActionDrop from './cr_action_drop.vue' ;
  import CrRawHtml           from 'packs/components/crud/cr_raw_html.vue'

  export default {
    name: 'CrTitle',
    components: {
      'cr-action': CrAction,
      'cr-raw-html'       : CrRawHtml,
      'cr-action-drop': CrActionDrop
    },
    props: ['title', 'subtitle', 'raw_subtitle', 'doc_link',
            'buttons', 'button_downs', 'actions'],
    data: function () {
      return {
        klass: 'cr_title'
      }
    },
    methods: {
      buttonsLargeClass: function() {
        if (this.buttons && (this.buttons.length == 1)) {
          return 'col-sm-4';
        }
        let tem = (this.buttons) && (this.buttons.length > 3)
        var klass = (tem ? 'col-sm-6' : 'col-sm-5')
        return klass
      },
      titleLargeClass: function() {
        let tem = (this.buttons) && (this.buttons.length > 3)
        var klass = (tem ? 'col-sm-6' : 'col-sm-7')
        return klass
      }
    },
    mounted: function () {
      // this.otitle = this.title;
      // this.oactions = this.actions;
    }
  };
</script>

